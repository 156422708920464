import { Box, ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { HeadProvider } from 'react-head'

import { Header } from './'
import { Footer } from './Footer'

export interface ILayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: ILayoutProps): JSX.Element => {
  const headTags = []

  return (
    <HeadProvider headTags={headTags}>
      <ChakraProvider>
        <Header />

        <Box as="main" maxW={980} mx="auto" px={8}>
          {children}
        </Box>

        <Footer />
      </ChakraProvider>
    </HeadProvider>
  )
}

export { Layout }
