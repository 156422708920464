import React from 'react'

import { Box, Heading, Text } from '@chakra-ui/react'

export interface IHeroProps {
  title: string
  text: string
}

const Hero = ({ title, text }: IHeroProps): JSX.Element => {
  return (
    <Box bg="#eb5f8e" p={12} w="100vw" ml="-50vw" left="50%" position="relative">
      <Box mx="auto" maxW={1000}>
        <Heading marginBottom={2}>{title}</Heading>
        <Text>{text}</Text>
      </Box>
    </Box>
  )
}

export { Hero }
