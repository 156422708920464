import React from 'react'

import { Box, Link, Text, Wrap, WrapItem } from '@chakra-ui/react'

const Footer = (): JSX.Element => {
  return (
    <Box bg="whitesmoke" py={8} as="footer" w="100%">
      <Box h="100%" maxW={980} mx="auto" px={8}>
        <Box>
          <Text>&copy; {new Date().getFullYear()} David Wheatley t/a DavMobile</Text>
          <Text fontSize="xs">
            DavMobile runs off an unnamed DOCSIS-based home internet provider. Funded through giffgaff payback.{' '}
            <Link isExternal href="https://www.giffgaff.com/about-us">
              What's giffgaff?
            </Link>
          </Text>
        </Box>
        <Box mt={2}>
          <Wrap>
            <WrapItem>
              <Link href="https://davwheat.dev" isExternal>
                Visit my personal site
              </Link>
            </WrapItem>
          </Wrap>
        </Box>
      </Box>
    </Box>
  )
}

export { Footer }
