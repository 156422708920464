import React from 'react'

import { Box } from '@chakra-ui/react'

export interface IBodySectionProps {
  children: React.ReactNode
  darker?: boolean
}

const BodySection = ({ children, darker = false }: IBodySectionProps): JSX.Element => {
  return (
    <Box bg={darker && 'whitesmoke'} w="100vw" ml="-50vw" left="50%" position="relative">
      <Box mx="auto" maxW={980} p={8}>
        {children}
      </Box>
    </Box>
  )
}

export { BodySection }
