import React from 'react'

import { Box } from '@chakra-ui/react'

import DavMobileLogoSrc from '../../assets/branding/logo_optimised.svg'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'gatsby'
import { HeaderNav } from './HeaderNav'

const useStyles = makeStyles({
  logoImg: {
    height: '100%',
  },
})

const Header = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Box as="header" w="100%" h={12} borderBottomWidth={1}>
      <Box h="100%" maxW={980} mx="auto" px={8}>
        <Box h="100%" display="flex">
          <Link to="/">
            <img draggable="false" className={classes.logoImg} alt="DavMobile" src={DavMobileLogoSrc} />
          </Link>

          {/* Spacer */}
          <Box flex="1" />

          <HeaderNav />
        </Box>
      </Box>
    </Box>
  )
}

export { Header }
